import { Link } from "react-router-dom";
import "./App.css";
import Parent from "./components/Parent";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Parent />
    </div>
  );
}

export default App;
