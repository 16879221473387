import "../App.css";
const Parent = () => {
  return (
    <>
      <iframe
        className="test-form"
        src="//mautic.penthara.com/form/1"
        width="100%"
        height="100%"
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </>
  );
};
export default Parent;
